<template>
  <div id="sai-tag-app">
    <div id="sai-tag-main">
      <router-view v-if="routing" />
      <FaqClientWrapper v-else />
    </div>
    <Multimodal />
  </div>
</template>

<script>
import Multimodal from '@/components/Multimodal';
import Const from './const';

export default {
  name: 'App',
  components: { Multimodal },
  data: () => {
    return { routing: Const.ROUTING };
  },
  async created() {
    await this.$store.dispatch('Initialize');
  },
};
</script>

<style lang="scss">
#sai-tag-app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
  margin: 0;
  overflow: visible;

  input,
  textarea,
  select,
  button {
    font-size: 16px;
  }
  button {
    border: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
}
</style>
