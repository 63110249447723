<template>
  <div
    class="sai-resourceTree"
    :class="
      isBigCategory(currentValue) && isSelected(currentValue) && 'addBottom'
    "
  >
    <div
      v-if="!isRoot && currentValue && currentValue.talkScriptType === 'node'"
      class="content"
      @click.prevent="toggleItems(currentValue)"
      :class="[
        isBigCategory(currentValue) ? 'withItems' : 'withoutItems',
        isActive(currentValue) && 'active',
        isSelected(currentValue) && 'selected',
      ]"
    >
      <span class="icon">
        <span class="plus">
          <font-awesome-icon icon="plus-circle" />
        </span>
        <span class="minus">
          <font-awesome-icon icon="minus-circle" />
        </span>
      </span>
      <span class="title">
        {{ currentValue.text }}
      </span>
    </div>

    <div
      v-if="isRoot || isActive(currentValue)"
      ref="items"
      :class="[
        'items',
        isActive(currentValue) && 'active',
        isSelected(currentValue) && 'selected',
      ]"
    >
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="['item', `talkScriptType-${item.talkScriptType}`]"
      >
        <ResourceTree
          :currentValue="item"
          :condition="condition"
          :isRoot="false"
          :showResult="showResult"
          :selectedCategories="selectedCategories"
          @setTagSearchShow="setTagSearchShow"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import ResourceTreeMixin from '@/mixins/ResourceTreeMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import { eventBus } from '@/eventBus';

@Component({})
export default class ResourceTree extends mixins(
  ResourceTreeMixin,
  ProductTicketMixin
) {
  generateTicket(item, startTime, query, tagActiveSet) {
    const commonTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    let newTicket = {
      ...commonTicket,
      status: 'open',
      items: item.items,
    };

    return { ...newTicket, ...this.customizedTicket };
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/resourceTree';
</style>
