<template>
  <div
    :id="id"
    :style="{
      visibility: isActive ? 'visible' : 'hidden',
      backgroundColor: backgroundColor,
    }"
    v-show="isActive"
  >
    <slot :isActive="isActive" />
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component({
  props: {
    id: { required: true },
    label: { required: true },
    handleClick: Function,
    backgroundColor: {
      type: String,
      default: '#fff',
    },
  },
})
export default class Tab extends Vue {
  isActive = true;
}
</script>
