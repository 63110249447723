import Vue from 'vue';
import Component from 'vue-class-component';
import Const from '../const/index';
import { eventBus } from '@/eventBus';

@Component({
  props: {},
})
export default class ProductFaqClientTagMixin extends Vue {
  mainTitle = Const.MAIN_TITLE;
  showDefaultHeader = Const.SHOW_DEFAULT_HEADER;
  showHeaderTitle = Const.SHOW_HEADER_TITLE;
  showHeaderIcon = Const.SHOW_HEADER_ICON;

  async customClear() {
    await eventBus.$emit('reset');
    setTimeout(() => {
      eventBus.$emit('scrollToTop');
    }, 0);
    if (this.$store.state.ticket.resetFlag) {
      this.resetTicket();
      return;
    }
    const newTicket = this.updateTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async backToSearch() {
    this.setTagSearchShow(true);
    setTimeout(() => {
      eventBus.$emit('scrollToSearch');
    }, 500);

    if (this.$store.state.ticket.resetFlag) {
      this.resetTicket();
      return;
    }

    const newTicket = this.updateTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }

  // Functions of InitialForm
  withInitialForm = Const.WITH_INITIAL_FORM;
  userInfoSubText = Const.USER_INFO_SUB_TEXT;
  logOutText = Const.LOGOUT_TEXT;

  get userId() {
    return this.$store.state.user.Id;
  }
  get userInfo() {
    return this.userInfoSubText + this.userId;
  }

  async removeUserId() {
    await this.$store.dispatch('user/updateId', '');
    await eventBus.$emit('reset');
    if (!this.$store.state.user.isSP) {
      setTimeout(() => {
        eventBus.$emit('scrollToTop');
      }, 0);
    }
    await eventBus.$emit('resetTicket');
  }
}
