import Vue from 'vue';
import Component from 'vue-class-component';
import { eventBus } from '@/eventBus';
import { getViewSections } from '@/common/resourceNavigationUtil';
import textParser from '@/libs/textParser';

@Component({
  props: {},
})
export default class FaqClientTagMixin extends Vue {
  ready = true;
  showAllTags = false;
  tagListTop = 0;
  tagListBottom = 0;
  selectedCategories = [];
  initAutoScroll = true;

  pcWithPopularTagsArea = process.env.PC_WITH_POPULAR_TAGS_AREA;
  pcWithImageTagsArea = process.env.PC_WITH_IMAGE_TAGS_AREA;
  pcWithFrequentFaqArea = process.env.PC_WITH_FREQUENT_FAQ_AREA;
  pcWithHistoryFaqArea = process.env.PC_HISTORY_FAQ_AREA;
  pcSciseedLink = process.env.PC_SCISEED_LINK;
  spWithFrequentFaqArea = process.env.SP_WITH_FREQUENT_FAQ_AREA;
  spWithImageTagsArea = process.env.SP_WITH_IMAGE_TAGS_AREA;
  spSciseedLink = process.env.SP_SCISEED_LINK;

  mainTitle = this.$store.state.constObj.MAIN_TITLE;
  showDefaultHeader = this.$store.state.constObj.SHOW_DEFAULT_HEADER;
  showHeaderTitle = this.$store.state.constObj.SHOW_HEADER_TITLE;
  showHeaderIcon = this.$store.state.constObj.SHOW_HEADER_ICON;
  headerIconFilename = this.$store.state.constObj.HEADER_ICON_FILE_NAME;
  searchInputTitle = this.$store.state.constObj.SEARCH_INPUT_TITLE;
  tagPopularTitle = this.$store.state.constObj.TAG_POPULAR_TITLE;
  frequentTitle = this.$store.state.constObj.FREQUENT_FAQ_TITLE;
  historyTitle = this.$store.state.constObj.HISTORY_FAQ_TITLE;
  latestUpdatedTitle = this.$store.state.constObj.LATEST_UPDATED_FAQ_TITLE;
  pastUpdatedTitle = this.$store.state.constObj.PAST_UPDATED_FAQ_TITLE;
  faqAnswerTitle = this.$store.state.constObj.FAQ_ANSWER_TITLE;
  backToSearchButtonText = this.$store.state.constObj
    .BACK_TO_SEARCH_BUTTON_TEXT;
  clearButtonTitle = this.$store.state.constObj.CLEAR_BUTTON_TEXT;
  withInitialForm = this.$store.state.constObj.WITH_INITIAL_FORM;
  userInfoSubText = this.$store.state.constObj.USER_INFO_SUB_TEXT;
  logOutText = this.$store.state.constObj.LOGOUT_TEXT;
  noCandidateFaqMessage = this.$store.state.constObj.NO_CANDIDATE_FAQ_MESSAGE;
  candidatesCountSubText = this.$store.state.constObj.CANDIDATES_COUNT_SUB_TEXT;
  pcHeaderLogoHeight = this.$store.state.constObj.PC_HEADER_LOGO_HEIGHT;
  spHeaderLogoHeight = this.$store.state.constObj.SP_HEADER_LOGO_HEIGHT;

  scrollContainer = 'body';
  scrollTopElement = this.$store.state.constObj.PC_SCROLL_TOP_ELEMENT;
  scrollSearchElement = '#sai-tag-search-input';
  scrollFaqElement = '#sai-faq-top';
  scrollAnswerElement = '#sai-scenario-answer';

  headerIconSrc(file) {
    return require(`product/resource/${file}`);
  }
  cleanText(text) {
    return textParser.cleanText(text);
  }
  existsFaqNo(text) {
    return textParser.existsFaqNo(text);
  }
  generateFaqNo(text) {
    return textParser.generateFaqNo(text);
  }
  customListHeight() {
    return this.tagListBottom - this.tagListTop;
  }
  isChrome() {
    return (
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
    );
  }
  calcBarWidth(count) {
    return count >= 100 ? 150 : 150 * (count / 100);
  }
  selectItem(item) {
    const routes = [];
    for (let current = item; current; current = current.parent) {
      routes.unshift(current);
    }
    routes.push.apply(routes, {});
    this.$store.commit('navigation/setRoutes', routes);
    this.$store.commit('navigation/setIndex', routes.length - 1);
  }
  setSearchText(searchText) {
    this.$store.dispatch('tagSearch/setSearchText', searchText);
  }
  removeSelectedTag(tag) {
    this.$store.dispatch('tagSearch/removeSelectedTag', tag);
  }
  addSelectedTag(tag) {
    this.$store.dispatch('tagSearch/addSelectedTag', tag);
  }
  onShowAllTags(value) {
    this.showAllTags = value;
  }
  setTagListTop() {
    this.tagListTop =
      this.$refs.taggedInput.offsetTop + this.$refs.taggedInput.offsetHeight;
    this.tagListBottom = this.tagListTop + this.$refs.tagList.offsetHeight;
  }
  handleScenario(e) {
    if (
      this.$refs.scenario &&
      !this.$refs.scenario.contains(e.target) &&
      this.$refs.listWrapper &&
      !this.$refs.listWrapper.contains(e.target) &&
      this.$refs.modal &&
      !this.$refs.modal.contains(e.target)
    ) {
      this.resetRoutes();
    }
  }
  handleTagList(e) {
    if (this.$refs.modal && this.$refs.modal.contains(e.target)) {
      this.setTagSearchShow(false);
    }
  }
  async customClear() {
    await eventBus.$emit('reset');
    setTimeout(() => {
      eventBus.$emit('scrollToTop');
    }, 0);
    if (this.$store.state.ticket.resetFlag) {
      this.resetTicket();
      return;
    }
    const newTicket = this.updateTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async backToSearch() {
    this.setTagSearchShow(true);
    setTimeout(() => {
      eventBus.$emit('scrollToSearch');
    }, 500);

    if (this.$store.state.ticket.resetFlag) {
      this.resetTicket();
      return;
    }

    const newTicket = this.updateTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async removeUserId() {
    await this.$store.dispatch('user/updateId', '');
    await eventBus.$emit('reset');
    if (!this.$store.state.user.isSP) {
      setTimeout(() => {
        eventBus.$emit('scrollToTop');
      }, 0);
    }
    await eventBus.$emit('resetTicket');
  }
  async resetInput() {
    await this.$store.dispatch('tagSearch/resetInput');
  }
  async updateTagsItems() {
    await this.$store.dispatch('tagSearch/updateTagsItems');
  }
  async setTagSearchShow(show) {
    setTimeout(() => {
      this.$store.commit('tagSearch/setShow', show);
    }, 0);
  }
  async tagfiyText(tagText) {
    await this.$store.dispatch('tagSearch/tagifyText', tagText);
  }
  async tagifyText(tagText) {
    console.log(tagText);
    await this.$store.dispatch('tagSearch/tagifyText', tagText);
  }
  async tagifyTextExact(tagText) {
    await this.$store.dispatch('tagSearch/tagifyTextExact', tagText);
  }
  resetRoutes() {
    const routes = [];
    for (
      let current = this.$store.state.navigation.routes[0];
      current;
      current = current.parent
    ) {
      routes.unshift(current);
    }
    routes.push.apply(routes, {});
    this.$store.commit('navigation/setRoutes', routes);
    this.$store.commit('navigation/setIndex', 0);
  }
  reset() {
    this.resetInput();
    this.updateTagsItems();
    this.setTagSearchShow(false);
    this.resetRoutes();
    this.setSelectedCategories([]);
  }
  resetWithScrollTop() {
    this.reset();
    if (!this.autoScrollElement || this.$store.state.user.isIphone) {
      setTimeout(() => {
        this.scrollToTop();
      }, 0);
    }
  }
  scrollTo(element, option) {
    this.$scrollTo(element, option);
  }
  scrollToTop() {
    const element = this.$store.state.user.isIphone
      ? this.iphoneTopElement
      : this.scrollTopElement;
    this.scrollTo(element, {
      container: this.scrollContainer,
      x: false,
    });
  }
  scrollToFaqTitle() {
    this.scrollTo(this.scrollFaqElement, {
      container: this.scrollContainer,
      x: false,
    });
  }
  scrollToSearch() {
    this.scrollTo(this.scrollSearchElement, {
      container: this.scrollContainer,
      x: false,
    });
  }
  autoScroll() {
    if (this.initAutoScroll) {
      this.setInitAutoScroll(false);
      return;
    }
    if (!this.$store.state.tagSearch.show) {
      this.scrollTo(this.autoScrollElement, {
        container: this.scrollContainer,
        x: false,
        duration: 1000,
      });
    }
  }
  setInitAutoScroll(boolean) {
    this.initAutoScroll = boolean;
  }
  setSelectedCategories(categories) {
    this.selectedCategories = categories;
  }
  resetTicket() {
    this.$ticket.reset();
    this.$store.dispatch('ticket/resetTicketState');
  }
  generateClearTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);

    const newTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      status: 'open',
    };

    return newTicket;
  }
  updateTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateTicket(startTime, query, tagActiveSet);
  }
  generateTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status: 'open',
    };
    return newTicket;
  }

  get numberOfKeywordsDisplayed() {
    return this.inputEmpty
      ? this.numberOfInitKeywordsDisplayed
      : this.numberOfSearchedKeywordsDisplayed;
  }
  get numberOfInitKeywordsDisplayed() {
    return this.$store.state.user.isSP
      ? this.$store.state.constObj.NUMBER_OF_INIT_KEYWORDS_DISPLAYED.SP
      : this.$store.state.constObj.NUMBER_OF_INIT_KEYWORDS_DISPLAYED.PC;
  }
  get numberOfSearchedKeywordsDisplayed() {
    return this.$store.state.user.isSP
      ? this.$store.state.constObj.NUMBER_OF_SEARCHED_KEYWORDS_DISPLAYED.SP
      : this.$store.state.constObj.NUMBER_OF_SEARCHED_KEYWORDS_DISPLAYED.PC;
  }
  get numberOfInitKeywordsRecommendDisplay() {
    // eslint-disable-next-line
    return this.$store.state.constObj.NUMBER_OF_INIT_KEYWORDS_DISPLAYED.PC_INITIAL;
  }
  get faqListTitle() {
    return this.inputEmpty
      ? this.frequentTitle
      : this.$store.state.constObj.CANDIDATE_FAQ_TITLE;
  }
  get imageTags() {
    return this.$store.state.constObj.imageTags;
  }
  get userId() {
    return this.$store.state.user.Id;
  }
  get userInfo() {
    return this.userInfoSubText + this.userId;
  }
  get sections() {
    return getViewSections(this.$store.state.navigation.routes);
  }
  get searchText() {
    return this.$store.state.tagSearch.searchText;
  }
  get show() {
    return this.$store.state.tagSearch.show;
  }
  get allTags() {
    return this.$store.state.tagSearch.candidateTags;
  }
  get initialTags() {
    return this.$store.state.tagSearch.initialTags;
  }
  get selectedTags() {
    return this.$store.state.tagSearch.selectedTags;
  }
  get candidateTags() {
    return this.$store.state.tagSearch.candidateTags;
  }
  get categorizedCandidateTags() {
    const tags = this.$store.state.tagSearch.candidateTags
      .filter(item => item.kana)
      .sort((a, b) => {
        return a.kana.localeCompare(b.kana);
      });
    const categorizedCandidateTags = [];
    let categoryName = null;
    for (const tag of tags) {
      const text = tag.kana;
      const currentCategoryName = text[0];
      if (categoryName !== currentCategoryName) {
        categorizedCandidateTags.push({
          title: currentCategoryName,
          tags: [],
        });
        categoryName = currentCategoryName;
      }
      categorizedCandidateTags[categorizedCandidateTags.length - 1].tags.push(
        tag
      );
    }
    return categorizedCandidateTags;
  }
  get filteredItems() {
    return this.$store.state.tagSearch.filteredItems;
  }
  get topTags() {
    return this.$store.state.tagSearch.topTags;
  }
  get loading() {
    return this.topTagsLoading && this.filteredItemsLoading && this.inputEmpty;
  }
  get topTagsLoading() {
    return Object.keys(this.$store.state.tagSearch.topTags).length === 0;
  }
  get filteredItemsLoading() {
    return this.$store.state.tagSearch.filteredItems.length === 0;
  }
  get inputTagEmpty() {
    return this.selectedTags.values().length === 0;
  }
  get inputEmpty() {
    return this.selectedTags.values().length === 0 && !this.searchText;
  }
  get isAutocompleteShown() {
    return this.$store.state.tagSearch.show;
  }
  get onIME() {
    return this.$store.state.onIME;
  }
  get showResult() {
    return this.sections.scenario.length > 0 || this.sections.result.length > 0;
  }
  get initialRecommendFaq() {
    return this.filteredItems.length > 0 && this.inputEmpty;
  }
  get noRecommendFaq() {
    return this.filteredItems.length === 0 && !this.inputEmpty;
  }
  get currentFaq() {
    return this.$store.state.navigation.routes[this.sections.talkScript.end];
  }
  get scriptAncesters() {
    return this.$store.state.navigation.routes[this.sections.talkScript.end]
      .ancesters;
  }
  created() {
    eventBus.$on('reset', () => {
      this.reset();
    });
    eventBus.$on('resetRoutes', () => {
      this.resetRoutes();
    });
    eventBus.$on('resetInput', () => {
      this.resetInput();
    });
    eventBus.$on('resetWithScrollTop', () => {
      this.resetWithScrollTop();
    });
    eventBus.$on('scrollToTop', () => {
      this.scrollToTop();
    });
    eventBus.$on('scrollToFaqTitle', () => {
      this.scrollToFaqTitle();
    });
    eventBus.$on('scrollToSearch', () => {
      this.scrollToSearch();
    });
    eventBus.$on('toggleIme', boolean => {
      this.$store.commit('setIME', boolean);
    });
    eventBus.$on('setSelectedCategories', categories => {
      this.setSelectedCategories(categories);
    });
    eventBus.$on('setTagSearchShow', boolean => {
      this.setTagSearchShow(boolean);
    });
    eventBus.$on('addSelectedTag', tag => {
      this.addSelectedTag(tag);
    });
    eventBus.$on('selectItem', item => {
      this.selectItem(item);
    });
    eventBus.$on('resetTicket', () => {
      this.resetTicket();
    });
  }
  updated() {
    this.setTagListTop();
  }
  mounted() {
    this.$refs.faqClientTag.addEventListener('click', e => {
      this.handleTagList(e);
    });
    this.setTagListTop();
    window.addEventListener('resize', () => {
      this.setTagListTop();
    });
  }
  beforeDestroy() {
    this.$refs.faqClientTag.removeEventListener(
      'click',
      e => {
        this.handleTagList(e);
      },
      false
    );
    window.removeEventListener('resize', this.setTagListTop);
  }
}
