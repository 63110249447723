var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sai-tabs"},[_c('ul',{staticClass:"tabs",style:({ 'background-color': _vm.backgroundColor })},_vm._l((_vm.tabs),function(tab,i){return _c('li',{key:i,class:{ active: tab.isActive },style:({
        color: tab.isActive ? _vm.activeColor : _vm.color,
        'background-color': tab.isActive
          ? _vm.activeBackgroundColor
          : _vm.backgroundColor,
      }),on:{"click":function($event){return _vm.select(tab.id)}}},[_c('span',[_vm._v(_vm._s(tab.label))])])}),0),_vm._v(" "),_c('div',{staticClass:"content",style:({
      minWidth: _vm.width,
    })},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }