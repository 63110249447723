import FaqClientWrapper from '../components/FaqClientWrapper';
import Const from '../const';

const baseUrl = process.env.BASE_PATH ? `/${process.env.BASE_PATH}/` : '/';
const mode = Const.ROUTING_MODE || 'hash';
const metaData = {
  title: Const.META_DATA.TITLE,
  // icon: 'fa-chart-line',
  type: process.env.TYPE,
};
if (Const.VARIABLE_META_DATA) {
  metaData.description = Const.META_DATA.DESCRIPTION;
  metaData.ogTitle = Const.META_DATA.OG_TITLE;
  metaData.ogDescription = Const.META_DATA.OG_DESCRIPTION;
}

const router = {
  base: baseUrl,
  mode: mode,
  routes: [
    {
      name: 'SingleFaqClient',
      path: '/:talkScriptId?/:stepEncoded?',
      component: FaqClientWrapper,
      meta: metaData,
    },
  ],
};

export default router;
