<template>
  <div class="sai-initial-form" :class="{ mobile: isSP }">
    <div class="sai-initial-form__title">
      <div class="text">{{ title }}</div>
    </div>
    <div class="sai-initial-form__input">
      <input
        type="text"
        required="true"
        @input="updateInput($event.target.value)"
        @keydown.enter="onClick"
      />
    </div>
    <div class="sai-initial-form__caption">
      <div class="text" v-html="caption"></div>
    </div>
    <div class="sai-initial-form__button">
      <button @click="onClick" v-bind:disabled="!validInput(value)">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { eventBus } from '@/eventBus';
import Const from '../const/index';

@Component({})
export default class InitialForm extends Vue {
  title = Const.INITIAL_FORM_TITLE;
  caption = Const.INITIAL_FORM_CAPTION;
  buttonText = Const.INITIAL_FORM_BUTTON_TEXT;
  value = '';
  onIME = false;

  get isSP() {
    return this.$store.state.user.isSP;
  }
  validInput(t) {
    return t && t.match(/^[A-Za-z0-9]*$/) && t.length < 7;
  }
  updateInput(t) {
    this.value = t;
  }
  async onClick() {
    if (!this.validInput(this.value)) {
      return;
    }
    await this.$emit('updateUserId', this.value);
    await this.$emit('issueInitialTicket');
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/initialForm';
</style>
