import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {},
})
export default class ProductTicketMixin extends Vue {
  get customizedTicket() {
    return {
      origin: 'page',
    };
  }
}
