<template>
  <ListContent v-if="faqList.length > 0" :list="list" @selected="selected" />
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import { eventBus } from '@/eventBus';
import { AsyncComputed } from '@/libs/vue-async-computed-decorator';
import ListContentWrapperMixin from '@/mixins/ListContentWrapperMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import frequent from '@/common/frequent';

@Component({
  components: {},
  props: {},
})
export default class Frequent extends mixins(
  ListContentWrapperMixin,
  ProductTicketMixin
) {
  faqList = [];
  async created() {
    this.faqList = await frequent.getFaqList(process.env.INITIAL_FAQ_FILE);
  }
  async selected(item) {
    if (this.$store.state.user.isIphone && this.$store.state.onIME) {
      eventBus.$emit('toggleIme', false);
      return;
    }
    await this.updateRoutes(item);
    if (this.$store.state.user.isSP) {
      this.$emit('setTagSearchShow', false);
    } else {
      setTimeout(() => {
        eventBus.$emit('scrollToFaqTitle');
      }, 100);
    }

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = await this.updateTicket({
      item: item,
      faqAction: 'frequent',
    });
    // For Customized Ticket
    await this.$ticket.setData({ ...newTicket, ...this.customizedTicket });
    this.$ticket.post();
  }
  @AsyncComputed()
  list() {
    return this.faqList;
  }
}
</script>
