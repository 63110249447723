<template>
  <div></div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({})
export default class FaqClientTag extends Vue {}
</script>
