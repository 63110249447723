import Vue from 'vue';
import App from './App';
import router from './router';
import Const from './const';
import store from './store/index';
import Router from 'vue-router';
import defaultComponents from '@/components';
import AsyncComputed from 'vue-async-computed-non-debounce';
import VueScrollTo from 'vue-scrollto';
import '@/libs/emulateScroll';
import '@/main.scss';

Vue.use(AsyncComputed);

const config = {
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: true,
  y: true,
};

Vue.use(VueScrollTo, config);

Vue.config.productionTip = false;

const vueSetting = {
  el: '#sai-tag-app',
  store,
  components: { App },
  template: '<App/>',
};

if (Const.ROUTING) {
  Vue.use(Router);
  vueSetting.router = new Router(router);
}

export const init = ({ components, config }) => {
  const mergedComponents = Object.assign({}, defaultComponents, components);
  for (const componentName of Object.keys(mergedComponents)) {
    Vue.component(componentName, mergedComponents[componentName]);
  }

  /* eslint-disable no-new */
  new Vue(vueSetting);
};

export const domReady = callback => {
  if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
  ) {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
};
