export class WordMapper {
  synonyms = {};

  constructor(synonymDict) {
    if (synonymDict) {
      this.synonyms = Object.entries(synonymDict).reduce(
        (obj, [key, value]) => {
          obj[key.toLowerCase()] = value;
          return obj;
        },
        {}
      );
    }
  }

  /**
   * Get representative word if one exists, otherwise the original word itself in lowercase.
   * @param {string} word Case insensitive word.
   * @return {string} Representative word.
   */
  getRepresentativeWord(word) {
    const normalizedWord = word.toLowerCase();
    return normalizedWord in this.synonyms
      ? this.synonyms[normalizedWord]
      : normalizedWord;
  }
}
