import resource from './resource';

class Scenario {
  readyPromise = null;
  constructor(_resource) {
    this.resource = _resource;
  }
  async get(id) {
    await this.ready();
    let scenario = {};
    for (const key in this.scenarios) {
      // eslint-disable-next-line eqeqeq
      if (id == this.scenarios[key].rootStep.scenarioId) {
        scenario = this.scenarios[key];
        break;
      }
    }
    return scenario;
  }
  getAsync(id) {
    return this.scenarios && this.scenarios[id];
  }
  ready() {
    if (!this.readyPromise) {
      this.readyPromise = this.prepare();
    }
    return this.readyPromise;
  }
  async prepare() {
    const data = await this.resource.ready();
    // scienarioをstepを中心とする形に変換する
    const scenarios = {};
    const travarseFlow = (flow, stepMap) => {
      const step = stepMap[flow.step];
      if (flow.next) {
        if (!step._options) {
          step._options = step.options;
        }
        step.options = flow.next.map(_flow => ({
          condition: _flow.condition,
          stepId: _flow.step,
          // step: stepMap[_flow.step],
        }));
        flow.next.forEach(_flow => {
          travarseFlow(_flow, stepMap);
        });
      }
      return step;
    };

    for (const scenario of data.scenario) {
      const rootStep = travarseFlow(scenario.flow[0], scenario.step);
      scenarios[scenario.id] = {
        rootStep,
        rootStepId: rootStep.id,
        steps: scenario.step,
        scenarioId: scenario.scenarioId,
      };
    }
    this.scenarios = scenarios;
    // console.log('scenarios', scenarios);
  }
}

const scenario = new Scenario(resource);
export default scenario;
