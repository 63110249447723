import { init, domReady } from './';
import components from './components';
import config from './config';
import './style/index.scss';
import Const from './const';
import Vue from 'vue';
import { SAITicketService } from '@/libs/sai-ticket/dist/sai-ticket.common.js';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons/faRedoAlt';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

if (!Const.USE_CUSTOMIZED_HTML) {
  require('./style/global/index.scss');
}

library.add(
  faList,
  faChevronRight,
  faChevronLeft,
  faSearch,
  faSignOutAlt,
  faRedoAlt,
  faTimes,
  faTag,
  faPlusCircle,
  faMinusCircle
);
components['font-awesome-icon'] = FontAwesomeIcon;

console.log('init', { components, config });

const ticketConfig = {
  ticketInterval: 1800,
};

Vue.use(SAITicketService, {
  config: ticketConfig,
  url: process.env.TICKET_URL,
  productId: process.env.RESOURCE_ID,
});

domReady(() => {
  init({ components, config });
});
