import { generateStore } from '@/store/index';
import enquete from './modules/enquete';
import customizedUser from './modules/customizedUser';
import createPersistedState from 'vuex-persistedstate';
import Const from '../const/index';

const localStoreSettings = {
  modules: {
    enquete,
  },
  plugins: [],
};
if (Const.WITH_INITIAL_FORM) {
  localStoreSettings.modules.user = customizedUser;
  localStoreSettings.plugins.push(
    createPersistedState({
      key: `sAIFaqClient-${process.env.PRODUCT_ID}`,
      paths: ['user.Id'],
    })
  );
}
const customizedStore = generateStore(localStoreSettings);

export default customizedStore;
