<template>
  <div class="sai-tabs">
    <ul class="tabs" :style="{ 'background-color': backgroundColor }">
      <li
        :class="{ active: tab.isActive }"
        :style="{
          color: tab.isActive ? activeColor : color,
          'background-color': tab.isActive
            ? activeBackgroundColor
            : backgroundColor,
        }"
        v-for="(tab, i) in tabs"
        :key="i"
        @click="select(tab.id)"
      >
        <span>{{ tab.label }}</span>
      </li>
    </ul>
    <div
      class="content"
      :style="{
        minWidth: width,
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component({
  props: {
    color: {
      type: String,
      default: '#3376ae',
    },
    backgroundColor: {
      type: String,
      default: '#fff',
    },
    activeColor: {
      type: String,
      default: '#fff',
    },
    activeBackgroundColor: {
      type: String,
      default: '#3376ae',
    },
  },
})
export default class Tabs extends Vue {
  width = 'auto';
  tabs = [];

  created() {
    this.tabs = this.$children;
  }

  mounted() {
    this.$nextTick(() => {
      // 一番大きなタブに合わせる
      const width = this.tabs
        .map(tab => tab.$el.clientWidth)
        .reduce((a, b) => Math.max(a, b));
      this.width = width + 'px';
      this.$nextTick(() => {
        const tabWidth = document.querySelector('ul.tabs').clientWidth;
        if (width < tabWidth) {
          this.width = tabWidth + 'px';
        }
      });

      this.select(this.tabs[0].id);
    });
  }

  select(id) {
    const selectedTab = this.tabs.find(tab => tab.id === id);
    this.tabs.forEach(tab => {
      tab.isActive = tab.id === selectedTab.id;
    });
  }
}
</script>

<style lang="scss" scoped>
.sai-tabs {
  display: flex;
  flex-direction: column;
}

ul {
  display: flex;
  justify-content: space-between;
  width: 100%;

  li {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 4px 16px;
    text-align: center;

    span {
      font-size: 12px;
      text-align: center;
    }

    &.active {
      border-radius: 8px 8px 0 0;
    }

    &.active:first-child {
      border-radius: 0 8px 0 0;
    }

    &.active:last-child {
      border-radius: 8px 0 0 0;
    }
  }
}
.content {
  height: 100%;
  display: flex;
  justify-content: center;
}
</style>
