import { uaParser } from '@/libs/uaParser';

export default {
  namespaced: true,
  state: {
    Id: null,
    userAgent: null,
    ArgentData: null,
    device: '',
    osName: '',
    browserName: '',
    isSP: false,
    isIphone: false,
    isEdge: false,
    isIE: false,
  },
  getterts: {},
  mutations: {
    initArgentData(state) {
      const ua = window.navigator.userAgent;
      state.userAgent = ua;
      const ArgentData = uaParser.getUa(ua);
      state.ArgentData = ArgentData;
      state.device = ArgentData.device.type || 'pc';
      state.osName = ArgentData.os.name;
      state.browserName = ArgentData.browser.name;
      state.isSP = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        ua
      );
      state.isIphone = /iPhone/i.test(ua);
      state.isEdge = /Edge/i.test(ua);
      state.isIE = /MSIE|Trident/i.test(ua);
    },
    setId(state, payload) {
      state.Id = payload;
    },
  },
  actions: {
    async init({ commit }) {
      commit('initArgentData');
    },
    updateId({ commit }, id) {
      commit('setId', id);
    },
  },
};
