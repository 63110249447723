import axios from 'axios';
import Const from '../const/index';

class Resource {
  constructor() {
    this.ready();
  }
  ready() {
    if (window.hasTagPackage) {
      return this.getWindowTagPackage();
    }
    if (!this.readyPromise) {
      this.readyPromise = this.prepare();
    }
    return this.readyPromise;
  }
  async prepare() {
    const startTime = new Date().getTime();
    const resourceUrl = `${
      process.env.RESOURCE_FILE_URL
    }?v=${this.randomNums()}`;
    const response = await axios.get(resourceUrl);
    const data = Const.CLEAN_TAG_PACKAGE_DATA
      ? this._cleanPackageData(response.data)
      : response.data;
    // console.log('script_package fetch time', new Date().getTime() - startTime);
    return data;
  }
  randomNums() {
    return Math.floor(Date.now() / 1000 / 60);
  }

  getWindowTagPackage() {
    return {
      inverted_index: window.inverted_index,
      scenario: window.scenario,
      talk_script: window.talk_script,
      script: window.script,
      synonym_dict: window.synonym_dict,
      tag_inverted_index: window.tag_inverted_index,
      tags: window.tags,
      variations_dict: window.variations_dict,
    };
  }

  /**
   * Clean up redundant keywords generated by NLP server
   * @param {*} data Tag package data
   * @returns Cleaned tag package data
   */
  _cleanPackageData(data) {
    /* e.g.
      synonym_dict: {
        PdfDoc: 'pdf',
      },
      inverted_index: {
        pdf: {},
        pdfdoc: {}, <- generated by NLP server
      },
      tag_inverted_index: {
       pdf: '111',
       PdfDoc: '111',
       pdfdoc: '139765889286696' <- generated by NLP server
      },
      variations_dict: {
        pdf: 'pdf',
        pdfdoc: 'pdfdoc', <- value should be PdfDoc
      },
      tags: [
        ['111', 'pdf', 'PDF', 'pdf', null, null, 5, 6],
        ['139765889286696', 'pdfdoc', 'PdfDoc', 'pdfdoc', null, null, 5, 1], <- generated by NLP server
      ],
      -> cleaned data
      synonym_dict: {
        PdfDoc: 'pdf',
      },
      inverted_index: {
        pdf: {},
      },
      tag_inverted_index: {
       pdf: '111',
       PdfDoc: '111',
      },
      variations_dict: {
        pdf: 'pdf',
        pdfdoc: 'PdfDoc'
      },
      tags: [
        ['111', 'pdf', 'PDF', 'pdf', null, null, 5, 6],
      ],
    */

    const uniqueTags = {};
    const redundantTags = {};

    const cleanedTagInvertedIndex = Object.entries(
      data.tag_inverted_index
    ).reduce((acc, [key, value]) => {
      const lowercaseKey = key.toLowerCase();
      const existingKey = uniqueTags[lowercaseKey];

      if (
        existingKey &&
        data.tag_inverted_index[key] !== data.tag_inverted_index[existingKey]
      ) {
        if (key === lowercaseKey) {
          redundantTags[key] = existingKey;
          return acc;
        } else {
          delete acc[existingKey];
          redundantTags[existingKey] = key;
        }
      }

      uniqueTags[lowercaseKey] = key;
      acc[key] = value;
      return acc;
    }, {});

    const redundantTagIds = [];
    for (const [key, value] of Object.entries(redundantTags)) {
      delete data.inverted_index[key];
      if (data.variations_dict[key]) {
        data.variations_dict[key] = value;
      }
      redundantTagIds.push(data.tag_inverted_index[key]);
    }

    data.tag_inverted_index = cleanedTagInvertedIndex;
    data.tags = data.tags.filter(t => !redundantTagIds.includes(t[0]));

    return data;
  }
}

const resource = new Resource();

export default resource;
