import resource from './resource';
import { ScriptMatchingManager } from '../libs/qaRetrieval';
import { utilityModules } from './util';

class Search {
  readyPromise = null;
  constructor(_resource) {
    this.resource = _resource;
  }

  async search(searchText) {
    await this.ready();
    if (searchText) {
      return this.scriptMatchingManager.getSearchResult(
        utilityModules.cleanText(searchText)
      );
    } else {
      return [];
    }
  }
  ready() {
    if (!this.readyPromise) {
      this.readyPromise = this.prepare();
    }
    return this.readyPromise;
  }
  async prepare() {
    const data = await this.resource.ready();
    this.scriptMatchingManager = new ScriptMatchingManager({
      matchingScript: data.script,
      invertedIndex: data.inverted_index,
      synonymDict: data.synonym_dict,
      variationsDict: data.variations_dict,
      invPostProbDist: data.script_by_id,
      categoryData: data.talk_script.body,
    });
    if (typeof window === 'object') {
      window.scriptMatchingManager = this.scriptMatchingManager;
    }
  }
}

const search = new Search(resource);
export default search;
