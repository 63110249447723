<template>
  <div class="multimodal"></div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class Multimodal extends Vue {}
</script>
