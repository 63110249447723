<template>
  <ScrollGuide ref="scrollGuide">
    <div
      :class="[
        'ResourceList',
        active && 'active',
        active && 'pageScrollTarget',
        enableScroll && 'scrollY',
        currentValue && currentValue.viewType === 'result' && 'result',
        currentValue && currentValue.viewType === 'scenario' && 'scenario',
      ]"
      v-scroll="onScroll"
    >
      <div ref="scrollWrapper" class="scrollWrapper">
        <div class="head" v-if="currentValue.viewType !== 'talkScript'">
          <div class="title faq">
            <div class="icon"><i class="top"></i></div>
            <div class="text">
              {{ cleanText(lastScript.text) }}
            </div>
          </div>
          <div class="subInfo" v-if="existsFaqNo(lastScript.text)">
            <span>{{ generateFaqNo(lastScript.text) }}</span>
          </div>
          <div
            class="title"
            v-for="(scenario, index) in scenarios"
            :key="index"
            :class="index === scenarios.length - 1 && 'lastChoice'"
            v-if="!currentValue.talkScriptId"
          >
            <div class="icon"><i class="choice"></i></div>
            <div class="text">
              <span>{{ scenario.text }}</span>
            </div>
          </div>
        </div>
        <div
          class="caption"
          v-show="item && item.caption"
          v-html="item && item.caption"
        ></div>
        <div ref="items" class="items">
          <div
            :class="[
              'item',
              isSelected(item) && 'selected',
              'talkScriptType-' + item.talkScriptType,
              firstIndex && 'first',
            ]"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="relation">
              <div class="relation-line"></div>
            </div>
            <div
              :class="['itemContent', index === 0 && 'firstItem']"
              @click.prevent="openHandler(item)"
              :item-debug="JSON.stringify(item)"
            >
              <span>{{ item.text }}</span>
              <font-awesome-icon icon="chevron-right" />
            </div>
          </div>
        </div>
        <ResourceListFooter
          :currentValue="currentValue"
          :lastScript="lastScript"
          :withEnquete="feedbackWithEnquete"
          :withSuggestion="feedbackWithSuggestion"
          @scrollBottom="scrollBottom"
        />
      </div>
    </div>
    <div ref="anchor" class="anchor">
      <span class="anchorBar"></span>
      <span class="anchorBox"></span>
    </div>
  </ScrollGuide>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import ResourceListMixin from '@/mixins/ResourceListMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import dataResource from '@/common/dataResource';
import { eventBus } from '@/eventBus';
import textParser from '../libs/textParser';
import Const from '../const/index';

@Component({})
export default class ResourceList extends mixins(
  ResourceListMixin,
  ProductTicketMixin
) {
  feedbackWithEnquete = Const.WITH_ENQUETE_RESOLVED;
  feedbackWithSuggestion = Const.WITH_ENQUETE_UNRESOLVED;

  cleanText(text) {
    return textParser.cleanText(text);
  }
  existsFaqNo(text) {
    return textParser.existsFaqNo(text);
  }
  generateFaqNo(text) {
    return textParser.generateFaqNo(text);
  }
  async openHandler(item) {
    if (item.talkScriptId && item.viewType === 'scenario') {
      const scenario = await dataResource.getItem(item);
      Object.assign(item.items, scenario.items);
    }
    this.open(item);
    if (
      item.talkScriptId === '#' ||
      (item.viewType === 'talkScript' && this.$store.state.ticket.resetFlag)
    ) {
      eventBus.$emit('resetTicket');
      return;
    }

    if (item.viewType !== 'talkScript') {
      this.$store.commit('ticket/setResetFlag', true);
    }
    const newTicket = this.updateTicket(item);
    // For Customized Ticket
    await this.$ticket.setData({ ...newTicket, ...this.customizedTicket });
    this.$ticket.post();
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/resourceList';
</style>
