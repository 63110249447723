import resource from '../resource';
import orderBy from 'lodash/orderBy';

class TagResource {
  // cache = new WeakMap();
  readyPromise = null;
  constructor(_resource) {
    this.rawResource = _resource;
    // this.tagData = {}
  }

  ready() {
    if (!this.readyPromise) {
      this.readyPromise = this.prepare();
    }
    return this.readyPromise;
  }

  async prepare() {
    const rawData = await this.rawResource.ready();
    // this.tagData = {
    //   tagInvertedIndex: rawData.tag_inverted_index,
    //   tagToId: rawData.tag_to_id
    // }
    // const mapByParentId = {};
    const mapById = {};
    const mapByText = {};
    const tags = [];
    const tagByYomi = {};
    rawData.tags.forEach(item => {
      const tagItem = Object.assign(
        ...[
          'id',
          'index',
          'text',
          'yomi',
          'relations',
          'attributes',
          'weight',
          'numOfScripts',
        ].map((k, i) => ({ [k]: item[i] }))
      );

      tagItem.type = 'keyword';
      tags.push({
        numOfScripts: tagItem.numOfScripts,
        tag: tagItem,
        weight: tagItem.weight,
      });
      mapById[tagItem.id] = tagItem;
      mapByText[tagItem.text] = tagItem;
    });

    // this.tagData = {...this.tagData, tags, mapById, mapByText}
    // this.mapByParentId = mapByParentId;
    // this.tagData.mapById = mapById;
    // this.tagData.mapByText = mapByText;
    // return this.tagData
    // this.mapByParentId = mapByParentId;
    this.tags = orderBy(
      tags,
      t => {
        return t.weight * t.numOfScripts;
      },
      'desc'
    );

    this.mapById = mapById;
    this.mapByText = mapByText;
  }

  async getList(params) {
    await this.ready();
    switch (params.mode) {
      case 'display':
        return this.tags.filter(item => {
          return item.tag.attributes && item.tag.attributes.dispayed;
        });
      case 'top':
        return this.tags.filter(item => {
          return item.tag.attributes && item.tag.attributes.top;
        });
      case 'init':
        return this.tags.filter(item => {
          return item.tag.attributes && item.tag.attributes.init;
        });
      default:
        if (params.limit) {
          return this.tags.slice(0, params.limit);
        }
        return this.tags;
    }
  }

  getListSync(params) {
    switch (params.mode) {
      case 'display':
        return this.tags.filter(item => {
          return item.tag.attributes && item.tag.attributes.dispayed;
        });
      case 'top':
        return this.tags.filter(item => {
          return item.tag.attributes && item.tag.attributes.top;
        });
      default:
        if (params.limit) {
          return this.tags.slice(0, params.limit);
        }
        return this.tags;
    }
  }

  isEquals(a, b) {
    return a.tagId === b.tagId;
  }

  getItemSync(params) {
    const item = this.mapById && this.mapById[params.tagId];
    return item;
  }

  async getItem(params) {
    await this.ready();
    const item = this.mapById[params.tagId];
    return item;
  }

  // topItem = {
  //   text: 'トップ',
  //   viewType: 'talkScript',
  //   parentViewType: 'talkScript',
  //   componentName: 'ResourceList',
  //   resourceName: 'talkScript',
  //   talkScriptId: '#',
  // };
  // getItemSync(params) {
  //   if (!params.talkScriptId || params.talkScriptId === '#') {
  //     return this.topItem;
  //   } else {
  //     const item = talkScript.getSync(params.talkScriptId);
  //     return item && this.convertItem(item);
  //   }
  // }

  // async getItem(params) {
  //   if (!params.talkScriptId || params.talkScriptId === '#') {
  //     return this.topItem;
  //   } else {
  //     const item = await talkScript.get(params.talkScriptId);
  //     return item && this.convertItem(item);
  //   }
  // }
  // convertItem = item => {
  //   if (!this.cache.has(item)) {
  //     const convertedItem = {
  //       text: item.text,
  //       caption: item.value,
  //       talkScriptId: item.id,
  //       componentName: 'ResourceList',
  //       parentViewType: 'talkScript',
  //       ancesters: item.ancesters,
  //       keywords: item.keywords,
  //       parent: this.getItemSync({
  //         talkScriptId: item.parent,
  //       }),
  //     };
  //     if (item.type === 'node') {
  //       this.cache.set(
  //         item,
  //         Object.assign(convertedItem, {
  //           resourceName: 'talkScript',
  //           viewType: 'talkScript',
  //           talkScriptType: 'node',
  //         })
  //       );
  //     } else if (item.type === 'leaf' && item.scenario) {
  //       this.cache.set(
  //         item,
  //         Object.assign(convertedItem, {
  //           resourceName: 'scenario',
  //           scenarioId: item.scenario,
  //           viewType: 'scenario',
  //           talkScriptType: 'leaf',
  //         })
  //       );
  //     } else {
  //       this.cache.set(
  //         item,
  //         Object.assign(convertedItem, {
  //           resourceName: 'talkScript',
  //           viewType: 'result',
  //           talkScriptType: 'leaf',
  //         })
  //       );
  //     }
  //   }
  //   return this.cache.get(item);
  // };
}
export const tagResource = new TagResource(resource);
