import Vue from 'vue';
import Component from 'vue-class-component';
import { extractQueryParams } from '@/common/uriUtil';
import dataResource from '@/common/dataResource';
import search from '@/common/tagSearch';

@Component({
  components: {},
  props: {},
})
export default class FaqClientWrapperMixin extends Vue {
  componentType = process.env.TYPE;
  nextRouteParams = null;
  checkedUrlParams = false;
  initialCategory = null;
  withInitialForm = this.$store.state.constObj.WITH_INITIAL_FORM;
  withEnquete =
    this.$store.state.constObj.WITH_ENQUETE_RESOLVED ||
    this.$store.state.constObj.WITH_ENQUETE_UNRESOLVED;

  get isReady() {
    return this.$store.getters['isReady'];
  }
  get existInput() {
    return (
      this.$store.state.tagSearch.selectedTags.values().length > 0 ||
      this.$store.state.tagSearch.searchText
    );
  }
  get userId() {
    return this.$store.state.user.Id;
  }
  get isHashRouting() {
    return (
      this.$store.state.constObj.ROUTING_MODE ===
      this.$store.state.constObj.ROUTING_MODES.HASH
    );
  }
  get isHistoryRouting() {
    return (
      this.$store.state.constObj.ROUTING_MODE ===
      this.$store.state.constObj.ROUTING_MODES.HISTORY
    );
  }
  get isLoading() {
    if (this.$store.state.constObj.CHECK_INITIAL_INPUT) {
      return !this.isReady || !this.checkedUrlParams;
    }
    return !this.isReady;
  }

  async postInitialForm(value) {
    await this.updateUserId(value);
    this.issueInitialTicket();
  }
  async updateUserId(id) {
    await this.$store.dispatch('user/updateId', id);
  }
  async resetEnquete() {
    await this.$store.dispatch('enquete/reset');
  }
  autoScroll() {
    if (
      'faqClient' in this.$refs &&
      'autoScrollElement' in this.$refs.faqClient
    ) {
      setTimeout(() => {
        this.$refs.faqClient.autoScroll();
      }, 0);
    }
  }
  updateMetaData() {
    let baseUrl =
      this.$store.state.constObj.META_DATA.URL +
      this.$router.options.base.slice(1);

    const targetRoutes = this.$store.state.navigation.routes.filter(r => {
      return r.viewType === 'result' || r.viewType === 'scenario';
    });

    if (targetRoutes.length === 0) {
      this.setMetaData(
        baseUrl,
        this.$store.state.constObj.META_DATA.TITLE,
        this.$store.state.constObj.META_DATA.DESCRIPTION
      );
      return;
    }

    const url = baseUrl + this.$route.path.slice(1);
    let title = '';
    const attach = this.$store.state.constObj.META_DATA.TITLE_ATTACH;
    for (var i in targetRoutes) {
      title += i > 0 ? attach + targetRoutes[i].text : targetRoutes[i].text;
    }
    title += this.$store.state.constObj.META_DATA.FIX_TITLE_TIP;
    const answer = targetRoutes[targetRoutes.length - 1];
    const description =
      answer.text +
      ' | ' +
      answer.caption.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '').substr(0, 400);
    this.setMetaData(url, title, description);
  }
  async invokeInitialInputData() {
    if (!document.location.search) {
      return;
    }

    const { tags, query, pcategory, ccategory, code } = extractQueryParams(
      document.location.search
    );
    if (!tags && !query && !pcategory && !code) return;
    if (code) {
      await dataResource.ready({ resourceName: 'talkScript' });
      const talkScripts = await dataResource.getList({
        resourceName: 'talkScript',
      });
      const question = talkScripts.find(x => x.text.includes(`[${code}]`));
      if (question) {
        // update router
        const routes = [];
        for (let current = question; current; current = current.parent) {
          routes.unshift(current);
        }
        routes.push.apply(routes, {});
        this.$store.commit('navigation/setRoutes', routes);
        this.$store.commit('navigation/setIndex', routes.length - 1);
        return;
      }
    }

    await this.setInitialInputData({
      pcategory,
      ccategory,
      tags: tags && tags.split(','),
      query,
    });
  }

  async setInitialInputData({ pcategory, ccategory, tags, query }) {
    // Category
    if (pcategory) {
      const category = await this.findSelectedCategory(pcategory, ccategory);
      if (category) {
        this.initialCategory = category;
        const displayText =
          category.parent.talkScriptId === '#'
            ? category.text
            : `${category.parent.text} > ${category.text}`;
        await this.$store.dispatch('tagSearch/addSelectedTag', {
          text: category.text,
          displayText: displayText,
          type: 'node',
          id: category.talkScriptId,
        });
      }
    }
    // Tag
    if (tags) {
      const values = await Promise.all(
        tags.map(tag => {
          return search.tagify(decodeURIComponent(tag));
        })
      );
      await Promise.all(
        values.map((tag, i) => {
          if (tag.length === 0) return Promise.resolve();
          // exact match で取得してきた tag の中で match 幅が最も大きいものを採用
          const newTag = tag.sort(
            (t1, t2) => t2.text.length - t1.text.length
          )[0];
          newTag.displayText = tags[i];
          return this.$store.dispatch('tagSearch/addSelectedTag', newTag);
        })
      );
    }
    // Query
    if (query) {
      await this.$store.dispatch('tagSearch/setSearchText', query);
    }

    await this.$store.dispatch('tagSearch/updateFilteredItems', true);
  }
  async findSelectedCategory(pcategory, ccategory) {
    await dataResource.ready({ resourceName: 'talkScript' });
    // Search Parent Category
    const plist = dataResource.getListSync({
      resourceName: 'talkScript',
      talkScriptId: '#',
    });
    const p = plist.find(n => {
      return n.text === decodeURIComponent(pcategory);
    });
    if (!p || !ccategory) {
      return p;
    }
    // Search Child Category
    const clist = dataResource.getListSync({
      resourceName: 'talkScript',
      talkScriptId: p.talkScriptId,
    });
    const c = clist.find(n => {
      return n.text === decodeURIComponent(ccategory);
    });
    return c || p;
  }
  async setTagSearchShow(show) {
    await this.$store.commit('tagSearch/setShow', show);
  }
  setCheckedUrlParams(boolean) {
    this.checkedUrlParams = boolean;
  }
  async issueInitialTicket() {
    const routeItem = this.$store.getters['navigation/getRoute'];

    // Route && No UrlParams
    if (routeItem.talkScriptId === '#' && !this.existInput) {
      return;
    }
    // Category && PC && No UrlParams
    if (
      routeItem.viewType === 'talkScript' &&
      !this.existInput &&
      !this.$store.state.user.isSP
    ) {
      return;
    }

    if (routeItem.viewType !== 'talkScript') {
      this.$store.commit('ticket/setResetFlag', true);
    }
    const newTicket = await this.updateTicket(routeItem);
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  updateTicket(item) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    return this.generateTicket(item, startTime);
  }
  async generateTicket(item, startTime) {
    let ticketParams = { items: item.items };
    switch (item.viewType) {
      case 'talkScript':
        ticketParams.status = 'open';
        if (!this.existInput) {
          break;
        }
        const openTicket = await this.generateOpenTicketParams();
        ticketParams = { ...ticketParams, ...openTicket };
        break;
      case 'scenario':
        ticketParams.status = 'answering';
        break;
      case 'result':
        ticketParams.status = 'answered';
        ticketParams.status_feedback = 'open';
        break;
    }
    const commonTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
    };

    return { ...commonTicket, ...ticketParams };
  }
  async generateOpenTicketParams() {
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      await this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }

    const query = this.$store.state.tagSearch.searchText;
    if (query) {
      await this.$store.commit('ticket/addHistoryQuery', query);
    }

    let historyActions = [];
    const categoryTags = this.$store.getters['tagSearch/selectedCategoryTags'];
    const existTags = tagActiveSet.length > 0;
    if (categoryTags.length > 0) {
      historyActions.push({ type: '', value: 'category' });
    } else if (existTags || query) {
      historyActions.push({ type: '', value: 'search' });
    }
    if (existTags) {
      historyActions.push({ type: 'tag', value: 'initial' });
    }
    if (historyActions.length > 0) {
      await this.$store.dispatch(
        'ticket/addHistoryActionFaqChannels',
        historyActions
      );
    }

    const params = {
      query: query,
      history_query: this.$store.state.ticket.historyQuery,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    if (this.initialCategory) {
      params.items = this.initialCategory.items;
      this.initialCategory = null;
    }

    return params;
  }
  cuntomHashRouting() {
    const newPath = window.location.hash.slice(1).split('/');
    const params = {
      talkScriptId: newPath[1] || undefined,
      stepEncoded: newPath[2] || undefined,
    };
    this.onRouteParamsChanged(params, this.$route.params);
  }
  setMetaData(url, title, description) {
    try {
      document.querySelector("link[rel='canonical']").setAttribute('href', url);
    } catch (error) {}

    document.title = title;
    document
      .querySelector("meta[name$='description'i]")
      .setAttribute('content', description);
  }
}
